import React, { Component } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import {
  Container,
  CardColumns,
  Card,
  Row,
  Col,
  Form,
  Button,
  Alert,
} from 'react-bootstrap'

import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  EmailIcon,
} from 'react-share'

import { siteMetadata } from '../../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Icon from 'components/icon'

import Logo from '../../../content/images/logo.png'

const cardsData = [
  {
    description:
      'The young generation of today should repeat the victory of Adwa by defeating current challenges and barriers.',
    name: 'Prime Minister of Ethiopia, Abiy Ahmed',
    city: 'Addis Ababa',
    country: 'Ethiopia',
    approved: true,
  },
  {
    description: 'ዘረኝነት እና መከፋፈልን ከሀገራችን እናጥፋ፤ የተማረና በምክንያት የሚከራከር ዜጋ እንፍጠር።',
    name: 'ጠቅላይ ሚኒስትር ዐቢይ አህመድ',
    city: 'አዲስ አበባ',
    country: 'ኢትዮጵያ',
    approved: true,
  },
  {
    description: 'ሞትን ማሸነፍ አይቻልም፤ በሕይወት ውስጥ ማሸነፍ የሚቻለው [ግን] ጥላቻን ብቻ ነው።',
    name: 'ጠቅላይ ሚኒስትር ዐቢይ አህመድ',
    city: 'አዲስ አበባ',
    country: 'ኢትዮጵያ',
    approved: true,
  },
  {
    description:
      'Love always wins. Killing others is a defeat, to those who tried to divide us, I want to tell you that you have not succeeded.',
    name: 'Prime Minister of Ethiopia, Abiy Ahmed',
    city: 'Addis Ababa',
    country: 'Ethiopia',
    approved: true,
  },
]

const shareUrl = 'https://medemer.et'

class Social extends Component {
  constructor(props) {
    super(props)
    this.state = { isSuccessful: false, showOtherSide: true }
  }

  getRandomInt = max => {
    return Math.floor(Math.random() * Math.floor(max))
  }

  handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({ isSuccessful: true })
  }

  onChange(event) {
    event.preventDefault()

    this.setState({ showOtherSide: true })
  }

  render() {
    const { isSuccessful, showOtherSide } = this.state
    const { location, data } = this.props

    const cards =
      cardsData &&
      cardsData.map((card, key) => {
        if (!card.approved) return

        return (
          <div key={key}>
            <Card bg={this.getRandomInt(2) ? 'secondary' : 'base'}>
              <blockquote
                className="blockquote mb-0 card-body text-center p-3"
                style={{ color: '#fff' }}
              >
                <p>{card && card.description}</p>
                <footer className="blockquote-footer" style={{ color: '#fff' }}>
                  <small>
                    {card && card.name} ({card && card.city},{' '}
                    {card && card.country}){' '}
                  </small>
                </footer>
              </blockquote>

              <Card.Footer style={{ background: '#fff' }}>
                <div className="text-center">
                  <img
                    src={Logo}
                    alt="Medemer Logo"
                    height="25"
                    style={{ marginBottom: '0px' }}
                  />
                </div>
              </Card.Footer>
              <Card.Footer style={{ background: '#fff' }}>
                <div className="text-center">
                  <ul
                    className="list-inline"
                    style={{ listStyle: 'none', display: 'inline' }}
                  >
                    <li
                      className="list-inline-item text-muted"
                      style={{ padding: '0 8px' }}
                    >
                      Share
                    </li>
                    <li
                      className="list-inline-item"
                      style={{ padding: '0 8px' }}
                    >
                      <TwitterShareButton
                        url={shareUrl}
                        title={card && card.description}
                        via="MedemerNow"
                        hashtags={['Medmer', 'መደመር']}
                      >
                        <TwitterIcon size={20} />
                      </TwitterShareButton>
                    </li>
                    <li
                      className="list-inline-item"
                      style={{ padding: '0 8px' }}
                    >
                      <FacebookShareButton
                        url={shareUrl}
                        quote={
                          card &&
                          `${card.description} https://medemer.et #Medmer #መደመር`
                        }
                      >
                        <FacebookIcon size={20} />
                      </FacebookShareButton>
                    </li>
                    <li
                      className="list-inline-item"
                      style={{ padding: '0 8px' }}
                    >
                      <a href="#">
                        <LinkedinShareButton
                          url={shareUrl}
                          windowWidth={750}
                          windowHeight={600}
                        >
                          <LinkedinIcon size={20} />
                        </LinkedinShareButton>
                      </a>
                    </li>
                    <li
                      className="list-inline-item"
                      style={{ padding: '0 8px' }}
                    >
                      <TelegramShareButton
                        url={shareUrl}
                        title={
                          card &&
                          `${card.description} https://medemer.et #Medmer #መደመር`
                        }
                      >
                        <TelegramIcon size={20} />
                      </TelegramShareButton>
                    </li>
                    <li
                      className="list-inline-item"
                      style={{ padding: '0 8px' }}
                    >
                      <WhatsappShareButton
                        url={shareUrl}
                        title={
                          card &&
                          `${card.description} https://medemer.et #Medmer #መደመር`
                        }
                        separator=":: "
                      >
                        <WhatsappIcon size={20} />
                      </WhatsappShareButton>
                    </li>
                  </ul>
                </div>
              </Card.Footer>
            </Card>
          </div>
        )
      })

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="#Medemer" />
        <div>
          <section className="jumbotron text-left">
            <div className="container">
              <h1>#መደመር</h1>
              <p className="lead text-muted">መደመር ለእርስዎ ምን ማለት ነው?</p>
            </div>
          </section>

          {isSuccessful ? (
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  <Alert variant="success">
                    Awesome! Thanks for sharing. All shared posts will be
                    reviewed prior to being posted.
                  </Alert>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              <Form onSubmit={this.handleSubmit.bind(this)}>
                <Row>
                  <Col xs={12} md={7}>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Control
                        required
                        as="textarea"
                        rows="6"
                        placeholder="መደመር ለእርስዎ ምን ማለት ነው..."
                        onChange={this.onChange.bind(this)}
                      />
                      <Form.Text className="text-muted">
                        <div>#መደመር</div>

                        <ol>
                          <li>መደመር ለእርስዎ ምን ማለት እንደሆነ በጥቂት ቃላት ይንገሩን</li>
                          <li>ሀሳቦችዎን ያጋሩ እና ውይይቱን ለመቀጠል ሌሎች የተናገሩትን ያንብቡ።</li>
                          <li>ሁሉም የተጋሩ ሃሳቦች ለሁሉም ከመታየታቸው በፊት ይገመገማሉ።</li>
                          <li>አግባብ ያልሆነ ወይም አፀያፊ ይዘት አይጋራም።</li>
                        </ol>
                      </Form.Text>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={5}>
                    {showOtherSide && (
                      <>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            xs={12}
                            controlId="formGridFullName"
                          >
                            <Form.Label>ሙሉ ስም</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="የእርስዎ ሙሉ ስም ማን ነው"
                            />
                          </Form.Group>

                          <Form.Group as={Col} xs={12} controlId="formGridCity">
                            <Form.Label>ከተማ</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="በየትኛው ከተማ ውስጥ ነው የሚኖሩት?"
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridCountry">
                            <Form.Label>የመኖሪያ አገር</Form.Label>
                            <Form.Control as="select">
                              <option defaultValue>ይምረጡ...</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="">---</option>
                              <option value="Afghanistan">Afghanistan</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="Andorra">Andorra</option>
                              <option value="Antigua and Barbuda">
                                Antigua and Barbuda
                              </option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia and Herzegovina">
                                Bosnia and Herzegovina
                              </option>
                              <option value="Botswana">Botswana</option>
                              <option value="Brazil">Brazil</option>
                              <option value="Brunei">Brunei</option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Central African Republic">
                                Central African Republic
                              </option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cote d'Ivoire">
                                Cote d'Ivoire
                              </option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">
                                Czech Republic
                              </option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">
                                Dominican Republic
                              </option>
                              <option value="East Timor">East Timor</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">
                                Equatorial Guinea
                              </option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Greece">Greece</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-Bissau">
                                Guinea-Bissau
                              </option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran">Iran</option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="North Korea">North Korea</option>
                              <option value="South Korea">South Korea</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Laos">Laos</option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libya">Libya</option>
                              <option value="Liechtenstein">
                                Liechtenstein
                              </option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macedonia">Macedonia</option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">
                                Marshall Islands
                              </option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia">Micronesia</option>
                              <option value="Moldova">Moldova</option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montenegro">Montenegro</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau">Palau</option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">
                                Papua New Guinea
                              </option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Romania">Romania</option>
                              <option value="Russia">Russia</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Kitts and Nevis">
                                Saint Kitts and Nevis
                              </option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Vincent and the Grenadines">
                                Saint Vincent and the Grenadines
                              </option>
                              <option value="Samoa">Samoa</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">
                                Sao Tome and Principe
                              </option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Serbia and Montenegro">
                                Serbia and Montenegro
                              </option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">
                                Solomon Islands
                              </option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syria">Syria</option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania">Tanzania</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Togo">Togo</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">
                                Trinidad and Tobago
                              </option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">
                                United Arab Emirates
                              </option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Vatican City">Vatican City</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Vietnam">Vietnam</option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>

                        <Button variant="primary" type="submit">
                          ይላኩ
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
              <hr />
            </Container>
          )}

          <Container>
            <Row>
              <Col xs={12} md={12}>
                <CardColumns>{cards}</CardColumns>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default Social

export const query = graphql`
  query AmSocialPageQuery {
    profile: file(name: { eq: "profile" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    work1: file(name: { eq: "work1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work2: file(name: { eq: "work2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work3: file(name: { eq: "work3" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back1: file(name: { eq: "back1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back2: file(name: { eq: "back2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
